import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import computeScrollIntoView from 'compute-scroll-into-view';
import Downshift from 'downshift';
import PropTypes from 'prop-types';
import { Debounce } from '@medifind/hooks';
import { isIOS } from '@medifind/utils';
import { Button } from './Button';
import styles from './Autocomplete-Select.module.scss';

function getScrollParent(node) {
  if (node == null) {
    return null;
  }
  const overflowY = window.getComputedStyle(node)['overflow-y'];
  if ((overflowY === 'scroll' || overflowY === 'auto') && node.scrollHeight > node.clientHeight) {
    return node;
  } else {
    return getScrollParent(node.parentElement);
  }
}
/**
 * TODO dont call api if text.trim.length === 0 ?
 */
const debounce = new Debounce(300, { runAtEnd: true }).predebounce;
const UL = ({ children, showDropdownPlaceHolder, isOpen, classes }) => {
  return (
    <ul
      className={classNames(styles['menu-list'], classes.menuList, {
        [classNames(styles['open'], classes.open)]: isOpen,
      })}
      style={showDropdownPlaceHolder && !isOpen ? { visibility: 'hidden', display: 'block' } : {}}
    >
      {children}
    </ul>
  );
};
const Items = ({
  items,
  isOpen,
  listRef,
  showDropdownPlaceHolder,
  classes,
  highlightedIndex,
  getItemProps,
  childrenSuggestion,
  id,
  isLoading,
  noResultsString,
  fillInput,
}) => {
  let mapIndex = 0;
  if (!isOpen) return showDropdownPlaceHolder && <div style={{ height: 1000 }}></div>;
  if (Array.isArray(items) && items.length) {
    // Render items with label headers
    if (items[0].items || items[0].label) {
      return (
        <div
          ref={listRef}
          className={classNames(styles['menu-scroll-container'], classes.menuScrollContainer, {
            [styles['menu-scroll-container--horizontal-labels']]: !fillInput,
            [classNames(styles['open'], classes.open)]: isOpen,
          })}
        >
          {items.map((item, index) => {
            if (!item.alwaysShow && (!item.items || !item.items.length)) {
              return null;
            }
            return (
              <UL key={`header-${index}`} {...{ showDropdownPlaceHolder, isOpen, classes }}>
                <li className={classNames(styles['menu-item-header'], classes.menuItemHeader)}>{item.label}</li>
                {item?.hint ? (
                  <li
                    className={classNames(styles['menu-item-hint'], styles['menu-item'], classes.menuItem)}
                    {...getItemProps({
                      key: 'menu-item-hint',
                      index: 'menu-item-hint',
                      item: { ...item?.hint, key: 'hint' },
                    })}
                  >
                    {item?.hint?.display} "{item?.hint?.link}"
                  </li>
                ) : null}
                {item.items.map((item, index) => {
                  mapIndex++;
                  return (
                    <Item
                      key={mapIndex}
                      classes={classes}
                      item={item}
                      index={index}
                      mapIndex={mapIndex}
                      highlightedIndex={highlightedIndex}
                      childrenSuggestion={childrenSuggestion}
                      getItemProps={getItemProps}
                    />
                  );
                })}
              </UL>
            );
          })}
        </div>
      );

      // Render items
    } else {
      return (
        <div
          ref={listRef}
          className={classNames(styles['menu-scroll-container'], classes.menuScrollContainer, {
            [classNames(styles['open'], classes.open)]: isOpen,
          })}
        >
          <UL {...{ showDropdownPlaceHolder, isOpen, classes }}>
            {items.map((item, index) => {
              mapIndex++;
              return (
                <Item
                  key={mapIndex}
                  classes={classes}
                  item={item}
                  index={index}
                  mapIndex={mapIndex}
                  highlightedIndex={highlightedIndex}
                  childrenSuggestion={childrenSuggestion}
                  getItemProps={getItemProps}
                />
              );
            })}
          </UL>
        </div>
      );
    }
    // Render an item that is a string, ex a custom not found message
  } else if (typeof items === 'string' || (typeof items === 'object' && !Array.isArray(items))) {
    return (
      <div
        ref={listRef}
        className={classNames(styles['menu-scroll-container'], classes.menuScrollContainer, {
          [classNames(styles['open'], classes.open)]: isOpen,
        })}
        {...(items?.ariaType && { [items?.ariaType]: items?.display })}
      >
        <UL {...{ showDropdownPlaceHolder, isOpen, classes }}>
          <li className={classNames(styles['menu-item'], classes.menuItem, items?.menuItem)}>{items?.display || items}</li>
        </UL>
      </div>
    );
  } else {
    // Render the loading message
    return (
      <div
        ref={listRef}
        className={classNames(styles['menu-scroll-container'], classes.menuScrollContainer, {
          [classNames(styles['open'], classes.open)]: isOpen,
        })}
      >
        <UL {...{ showDropdownPlaceHolder, isOpen, classes }}>
          <li className={classNames(styles['menu-item'], classes.menuItem)}>{isLoading ? 'Loading ...' : noResultsString}</li>
        </UL>
      </div>
    );
  }
};

const Item = ({ item, mapIndex, index, classes, getItemProps, childrenSuggestion, highlightedIndex }) => {
  return (
    <li
      key={mapIndex}
      className={classNames(
        styles['menu-item'],
        classes['clickable-menu-item'],
        classes.menuItem,
        classes.validMenuItem,
        item.className,
        {
          [classNames(styles['highlighted'], classes['highlighted'])]: highlightedIndex === mapIndex,
        },
        {
          [classNames(styles['disabled'], classes['disabled-menu-item'])]: item?.isDisabled,
        },
        `${item.key}-${index}`,
      )}
      {...getItemProps({
        key: mapIndex,
        index: mapIndex,
        item,
      })}
    >
      {childrenSuggestion ? childrenSuggestion(item) : item.display}
    </li>
  );
};

const Autocomplete = ({
  id,
  label,
  labelId,
  hideLabel,
  autoFocus,
  placeholder = 'Type to search',
  noResultsString = 'No Results',
  onChange,
  onFocus,
  onEnter,
  onSelect,
  onClear,
  items,
  classes = {},
  style = {},
  className,
  activeFirstOption = null,
  initialValue,
  inputValue,
  inputIcon = [
    {
      clearInput: true,
    },
  ],
  childrenPreInputWrapper = () => {},
  childrenInsideWrapper = () => {},
  childrenOutsideWrapper = () => {},
  childrenPostInputWrapper = () => {},
  childrenPostMenuWrapper = () => {},
  childrenSuggestion,
  clearInputAfterSelect = false,
  actLikeASelect,
  inputRef,
  showDropdownPlaceHolder,
  onBlur,
  listRef,
  autocomplete = 'off',
  showWrapper = true,
  fillInput = true,
  onMenuStatusChange,
  inputProp,
  disabled,
  clearSelectOnTyping,
}) => {
  const _internalRef = useRef(null);
  const isDirty = useRef(false);
  const openRef = useRef(false);
  const internalRef = inputRef ? inputRef : _internalRef;
  const inputShouldChange = useRef(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [input, setInput] = useState(inputValue || '');

  const handleChange = (value, args) => {
    isDirty.current = true;
    onChange && onChange(value, args);
  };

  const handleEnter = (value, args) => {
    isDirty.current = false;
    onEnter(value, args);
  };

  const handleSelect = (value, args) => {
    isDirty.current = false;
    onSelect(value, args);
  };

  useEffect(() => {
    if (items?.length > 0 || !isFirstLoad) setIsLoading(false);
    setIsFirstLoad(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  useEffect(() => {
    if (inputShouldChange.current) {
      setInput(inputValue || '');
    }
    isDirty.current = false; // External change, assuming its changing the value and the input is no longer dirty
  }, [inputValue, inputProp]);

  const scrollToIfTouchDevice = () => {
    if (window.matchMedia('(any-hover: none)').matches && !isIOS()) {
      const parent = getScrollParent(internalRef.current) || window;

      const elementPosition = internalRef.current.getBoundingClientRect().top;
      const offsetPosition =
        parent.scrollY != null ? elementPosition + parent.scrollY - 45 : parent.offsetTop + internalRef.current.offsetTop - 45;

      parent.scrollTo({
        top: offsetPosition,
        behavior: 'instant',
      });
    }
  };

  const preventInputFromChanging = () => {
    // Block the input from changing for the next rerender
    inputShouldChange.current = false;
    setTimeout(() => {
      inputShouldChange.current = true;
    }, 500);
  };

  // console.log(label, labelId);
  return (
    <Downshift
      onStateChange={({ isOpen }) => {
        if (isOpen != null) {
          if (openRef.current !== isOpen) {
            onMenuStatusChange && onMenuStatusChange({ isOpen });
            openRef.current = isOpen;
          }
        }
      }}
      scrollIntoView={(node, menuNode) => {
        if (node === null) {
          return;
        }

        const actions = computeScrollIntoView(node, {
          // Disabling boundary to test if scrolling works from Popper
          // boundary: rootNode,
          block: 'nearest',
          scrollMode: 'if-needed',
        });
        actions.forEach(({ el, top, left }) => {
          el.scrollTop = top;
          el.scrollLeft = left;
        });
      }}
      labelId={labelId}
      stateReducer={(state, changes) => {
        switch (changes.type) {
          // uncomment below to stop dropdown from collapsing during testing
          // case Downshift.stateChangeTypes.blurInput:
          // case Downshift.stateChangeTypes.mouseUp:
          //   return {};

          case Downshift.stateChangeTypes.keyDownArrowDown: // don't open empty menu on arrow down
          case Downshift.stateChangeTypes.keyDownArrowUp:
            const direction = changes.highlightedIndex - state.highlightedIndex;
            let highlightedIndex = changes.highlightedIndex === 0 ? 1 : changes.highlightedIndex;
            while (items[highlightedIndex - 1]?.isDisabled) highlightedIndex += direction;
            return {
              ...changes,
              highlightedIndex,
              isOpen: state.isOpen || state.inputValue.length > 0 || actLikeASelect,
            };
          case Downshift.stateChangeTypes.keyDownEscape: // don't clear input & don't clear selectedItem
            return {
              ...changes,
              inputValue: state.inputValue,
              selectedItem: state.selectedItem,
            };
          case Downshift.stateChangeTypes.blurInput:
          case Downshift.stateChangeTypes.mouseUp: // don't clear input
            // Bug when clicking off screen, focus is not lost
            internalRef.current?.blur();
            return {
              ...changes,
              inputValue: state.inputValue,
            };
          case Downshift.stateChangeTypes.changeInput: // close menu when no input
            return {
              ...changes,
              isOpen: (actLikeASelect || changes.inputValue.length > 0) && changes.isOpen,
            };
          default:
            return { ...changes };
        }
      }}
      onChange={(value, ref) => {
        if (value?.isDisabled) {
          ref.clearSelection();
          internalRef.current?.blur();
          return;
        }
        handleSelect(value, ref);
        if (!activeFirstOption) {
          ref.clearSelection();
        }
        if (clearInputAfterSelect) {
          // TODO why did i add this  && (inputValue || '') !== ''
          internalRef.current?.blur();
          ref.reset();
          setInput('');
          handleChange('', ref);
        } else if (actLikeASelect) {
          internalRef.current?.blur();
          // ref.reset();
          // setInput('');
          // handleChange('', ref);
        }
      }}
      itemToString={(item) => (item ? item.display : '')}
      {...(initialValue ? { initialSelectedItem: initialValue, selectedItem: initialValue } : { inputValue: input })}
      defaultHighlightedIndex={(activeFirstOption || null) && 1}
    >
      {(args) => {
        const {
          getLabelProps,
          getInputProps,
          getMenuProps,
          getItemProps,
          isOpen,
          highlightedIndex,
          clearSelection,
          openMenu,
          inputValue,
          reset,
        } = args;
        return (
          <div
            style={style.autocomplete}
            className={classNames(styles['shared-autocomplete'], classes.autocomplete, className)}
            {...(id ? { id: id + '-container' } : {})}
          >
            {labelId ? (
              getLabelProps({ id: labelId, htmlFor: id }) && undefined
            ) : (
              <label
                style={{ ...style.label, ...(hideLabel ? { display: 'none' } : {}) }}
                {...getLabelProps(id ? { htmlFor: id } : {})}
                className={classNames(styles['label'], classes.label)}
              >
                {label}
              </label>
            )}
            {childrenOutsideWrapper(args)}
            <div
              style={style.menuWrapper}
              className={classNames(
                styles['menu-wrapper'],
                {
                  [styles['menu-wrapper--wrapping']]: showWrapper,
                  [styles['menu-wrapper--fill-input']]: fillInput,
                  [classNames(styles['open'], classes.open)]: isOpen,
                },
                classes.menuWrapper,
              )}
            >
              {childrenInsideWrapper(args)}
              <div
                style={style.inputWrapper}
                className={classNames(styles['autocomplete__input-wrapper'], classes.inputWrapper, {
                  [classNames(styles['open'], classes.open)]: isOpen,
                })}
                disabled={disabled}
              >
                {childrenPreInputWrapper(args)}
                <input
                  style={style.input}
                  {...(autoFocus ? { autoFocus: true } : {})}
                  className={classNames(
                    styles['autocomplete-input'],
                    { [styles['autocomplete-input--wrapping']]: showWrapper },
                    classes.input,
                    {
                      [classNames(styles['open'], classes.open)]: isOpen && !classes.inputNoOpen,
                    },
                  )}
                  {...getInputProps({
                    ref: internalRef,
                    onFocus: (event) => {
                      internalRef.current.readOnly = true;
                      scrollToIfTouchDevice();
                      internalRef.current.readOnly = false;
                      internalRef.current.focus();
                      event.nativeEvent.preventDownshiftDefault = true;

                      onFocus && onFocus(args);
                      if ((inputValue && isDirty.current) || actLikeASelect) {
                        // Open menu on focus & having suggestions
                        openMenu();
                      }
                    },
                    onBlur: () => {
                      onBlur && onBlur(args);
                      if (clearSelectOnTyping && onClear) {
                        isDirty.current && onClear();
                        onSelect({}, args);
                        preventInputFromChanging();
                      }
                    },
                    onKeyDown: (event) => {
                      if (event.key === 'Enter') {
                        if (onEnter && (highlightedIndex == null || highlightedIndex == 0)) {
                          onEnter && handleEnter(event?.target?.value, args);
                          clearSelection();
                          if (clearInputAfterSelect && (inputValue || '') !== '') {
                            reset();
                            setInput('');
                            handleChange('', args);
                          }
                        }
                      } else if (event.key === 'Home') {
                        event.nativeEvent.preventDownshiftDefault = true;
                      } else if (event.key === 'End') {
                        event.nativeEvent.preventDownshiftDefault = true;
                      } else if (event.key === 'Backspace') {
                        onClear && onClear();
                      }
                    },

                    onChange: (event) => {
                      const { value } = event.target;
                      onChange && setIsLoading(true);
                      setInput(value);

                      debounce(() => handleChange(value, args));
                    },
                    placeholder: placeholder,
                    ...(id ? { id: id } : {}),
                    autoComplete: autocomplete,
                  })}
                  autoComplete={autocomplete}
                  disabled={disabled}
                />
                {inputIcon?.length > 0 &&
                  inputIcon.map(
                    ({ icon, onClick, label, clearInput, ignoreDisable }, i) =>
                      icon && (
                        <Button
                          key={i}
                          style={{ font: 'initial', background: 'none', container: { alignSelf: 'stretch' } }}
                          icon={icon}
                          focus="outline"
                          onClick={(e) => {
                            onClick && onClick(e);
                            if (clearInput) {
                              setInput('');
                            }
                          }}
                          classes={{ icon: classNames(styles['icon'], classes.icon) }}
                          screenReaderText={label}
                          disabled={disabled && !ignoreDisable}
                        />
                      ),
                  )}
              </div>
              {childrenPostInputWrapper(args)}
              <div
                className={classNames(styles.menu, classes.menu, {
                  [classNames(styles['open'], classes.open)]: isOpen,
                })}
                style={{ ...(style.menu ?? {}), ...(showDropdownPlaceHolder && !isOpen ? { visibility: 'hidden', display: 'block' } : {}) }}
                {...getMenuProps()}
              >
                <Items
                  {...{
                    items,
                    isOpen,
                    listRef,
                    showDropdownPlaceHolder,
                    classes,
                    highlightedIndex,
                    getItemProps,
                    childrenSuggestion,
                    id,
                    isLoading,
                    noResultsString,
                    fillInput,
                  }}
                />
              </div>
            </div>
            {childrenPostMenuWrapper(args)}
          </div>
        );
      }}
    </Downshift>
  );
};

Autocomplete.propTypes = {
  label: function (props, propName, componentName) {
    if (!props.labelId && !props.label) {
      return new Error(`One of 'label' or 'labelId' is required by '${componentName}' component.`);
    }
    if (props.labelId) {
      PropTypes.checkPropTypes(
        {
          labelId: PropTypes.string, // or any other PropTypes you want
        },
        props,
        propName,
        componentName,
      );
    }
    return null;
  },
  labelId: PropTypes.string,
  hideLabel: PropTypes.bool,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  noResultsString: PropTypes.string,
  onChange: PropTypes.func,
  onEnter: PropTypes.func,
  onSelect: PropTypes.func.isRequired,
  onClear: PropTypes.func,
  activeFirstOption: PropTypes.bool,
  autoFocus: PropTypes.bool,
  clearInputAfterSelect: PropTypes.bool,
  actLikeASelect: PropTypes.bool,
  showDropdownPlaceHolder: PropTypes.bool,
  initialValue: PropTypes.shape({
    display: PropTypes.string,
    value: PropTypes.any,
    className: PropTypes.string,
  }),
  inputValue: PropTypes.string,
  items: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        display: PropTypes.string.isRequired,
        value: PropTypes.any.isRequired,
        className: PropTypes.string,
      }),
    ),
    PropTypes.string,
    PropTypes.array,
    PropTypes.object,
  ]).isRequired,
  style: PropTypes.object,
  classes: PropTypes.shape({
    autocomplete: PropTypes.string,
    menuWrapper: PropTypes.string,
    open: PropTypes.string,
    input: PropTypes.string,
    inputNoOpen: PropTypes.bool,
    menu: PropTypes.string,
    highlighted: PropTypes.string,
    menuItem: PropTypes.string,
    menuItemHeader: PropTypes.string,
    validMenuItem: PropTypes.string,
    menuList: PropTypes.string,
    inputWrapper: PropTypes.string,
    label: PropTypes.string,
    icon: PropTypes.string,
  }),
  className: PropTypes.string,
  inputIcon: PropTypes.arrayOf(PropTypes.shape({ icon: PropTypes.node, onClick: PropTypes.func })),
  childrenPreInputWrapper: PropTypes.func,
  childrenInsideWrapper: PropTypes.func,
  childrenOutsideWrapper: PropTypes.func,
  childrenPostInputWrapper: PropTypes.func,
  childrenPostMenuWrapper: PropTypes.func,
  childrenSuggestion: PropTypes.func,
  inputRef: PropTypes.any,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onMenuStatusChange: PropTypes.func,
  listRef: PropTypes.object,
  autocomplete: PropTypes.string,
  showWrapper: PropTypes.bool,
  fillInput: PropTypes.bool,
  inputProp: PropTypes.object,
  clearSelectOnTyping: PropTypes.bool,
};

export { Autocomplete as default, Autocomplete };
